.hexbackground {
  background-image: url("../../Assets/Circuit\ Primary.svg"),
    url("../../Assets//Circuit\ Primary\ \(1\).svg");
  background-position: left, right;
  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain;
}

.anim_bg {
  background-image: url("../../Assets//Animated\ Shape.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 576px) {
  .hexbackground {
    background-image: none;
  }
}
