/* From uiverse.io by @alexmaracinaru */

body {
  overflow-y: auto !important;
}

.cta {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
}

.cta:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #b1dae7;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #234567;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #234567;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #b1dae7;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}

.progress {
  max-width: 21.875rem;
  margin: 0 auto;
  border-radius: 50px;
}

.progress .progress-bar {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#18a0f9),
    to(#0a6bb8)
  ) !important;
  background: linear-gradient(to right, #18a0f9, #0a6bb8) !important;
}

.f_btn {
  font-size: 1.125rem;
  font-weight: 800;
  padding: 0.9375rem 1.5625rem;
  outline: none;
  border: none;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#18a0f9),
    to(#0a6bb8)
  );
  background: linear-gradient(to right, #18a0f9, #0a6bb8);
}

.f_btn:hover {
  color: #ffffff;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0a6bb8),
    to(#18a0f9)
  );
  background: linear-gradient(to right, #0a6bb8, #18a0f9);
  -webkit-transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.prev_btn {
  left: 0;
  bottom: 0;
}

.next_btn {
  right: 0;
  bottom: 0;
}

.row li.active {
  color: #22a247;
  border: 2px solid #22a247;
  border-radius: 0.3125rem;
  background-color: #def7e596;
}

.row li.active:after {
  background-color: #ffffff;
}
