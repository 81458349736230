.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #4281c5;
}

.countdown-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 170px;
  border: 2px solid #4281c5;
  border-radius: 5px;
}

.countdown-value {
  font-size: 2em;
}

.countdown-label {
  text-transform: uppercase;
}
