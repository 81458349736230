.forced-left-container {
  margin-left: -0.7rem !important;
}

.count_box {
  transform: scale(0.8);
  padding-right: 1rem;
  padding-left: 1.5rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 17rem;
  min-height: 5.625rem;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#18c1f9),
    to(#0a67b8)
  );
  background: linear-gradient(to right, #18c1f9, #0a67b8);
  font-family: "Jost", sans-serif;
}
